import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { DougsDatePipe, ModalService, TooltipDirective } from '@dougs/ds';

@Component({
  selector: 'dougs-payslip-list',
  templateUrl: './payslip-list.component.html',
  styleUrls: ['./payslip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgClass, TooltipDirective, NgIf, TitleCasePipe, DougsDatePipe],
})
export class PayslipListComponent implements OnInit {
  public hasAtLeast5Payrolls!: boolean;
  public filteredPayrolls: Attachment[] = [];

  private _payrolls!: Attachment[];
  @Input()
  set payrolls(payrolls: Attachment[]) {
    this._payrolls = payrolls;
    this.hasAtLeast5Payrolls = this.payrolls?.length > 5;
  }

  get payrolls(): Attachment[] {
    return this._payrolls;
  }

  @Input() showFooter = true;

  constructor(
    private readonly window: Window,
    private readonly modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.filterPayrolls();
  }

  trackById(index: number, item: Attachment): number | string {
    return item.id;
  }

  downloadAttachment(payroll: Attachment): void {
    this.window.open(payroll.file.url, '_blank');
  }

  async showMorePayslips(): Promise<void> {
    const { PayslipListModalComponent } = await import(
      '../../../../../../../payslip-list-modal/payslip-list-modal.component'
    );
    this.modalService.open(PayslipListModalComponent, {
      data: this.payrolls,
    });
  }

  private filterPayrolls(): void {
    if (this.payrolls) {
      this.filteredPayrolls = this.hasAtLeast5Payrolls && this.showFooter ? this.payrolls.slice(0, 5) : this.payrolls;
    }
  }
}
