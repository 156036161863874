<div class="payslips mb-16">
  <div class="payslips__row table-header py-4 px-16">
    <div class="payslips__row__element month">Mois</div>
    <div class="payslips__row__element created-at">Ajoutée le</div>
  </div>
  <div
    *ngFor="let payroll of filteredPayrolls; trackBy: trackById; let lastRow = last"
    class="payslips__row py-8 px-16"
    [ngClass]="{ 'no-border': lastRow && !hasAtLeast5Payrolls }"
  >
    <div class="payslips__row__element"><i class="fal fa-file-pdf"></i></div>
    <div class="payslips__row__element">
      {{ payroll.metadata.payslipDate | date: 'MMMM yyyy' | titlecase }}
    </div>
    <div class="payslips__row__element">{{ payroll.createdAt | date: 'dd/MM/yyyy' }}</div>
    <div
      class="payslips__row__element download-icon"
      [dougsTooltip]="payroll.file?.name"
      (click)="downloadAttachment(payroll)"
    >
      <i class="fal fa-download"></i>
    </div>
  </div>
  <div class="footer py-8 px-16" (click)="showMorePayslips()" *ngIf="hasAtLeast5Payrolls && showFooter">
    <p class="tiny">
      Afficher toutes les fiches de paie
      <i class="fal fa-chevron-down ml-4"></i>
    </p>
  </div>
</div>
